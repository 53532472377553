<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <div class="mb-5">
      <div class="d-flex flex-column align-center">
        <div>
          <v-img width="150px" :src="image" alt="background" />
        </div>
      </div>
      <v-file-input
        v-model="file"
        :rules="isRequired ? [] : MIXINS_REQUIRED_RULES"
        accept="image/*"
        label="Upload Badge"
        prepend-icon="mdi-camera"
        dense
        @change="createImage()"
        class="mt-5"
        :clearable="false"
        :validate-on-blur="true"
        :error-messages="file || isRequired ? [] : MIXINS_REQUIRED_RULES"
        append-icon="mdi-close"
        @click:append="defaultImage()"
      />
    </div>
    <v-text-field
      v-model="form.name"
      :rules="MIXINS_REQUIRED_RULES"
      label="Name"
      dense
      filled
      class="rounded-lg"
    />
    <v-textarea
      v-model="form.description"
      :rules="MIXINS_REQUIRED_RULES"
      label="Description"
      dense
      filled
      rows="3"
      class="rounded-lg"
    />
    <badge-category-select-box
      v-model="form.category"
      item-value="value"
      item-text="name"
      :rules="MIXINS_REQUIRED_RULES"
      label="Category"
      dense
      filled
      class="rounded-lg"
    >
    </badge-category-select-box>
  </v-form>
</template>

<script>
const BadgeCategorySelectBox = () =>
  import("../selects/BadgeCategorySelectBox.vue");

export default {
  components: {
    BadgeCategorySelectBox
  },
  data: () => ({
    valid: false,
    file: null,
    image: null
  }),
  props: {
    /**
     * Collection of data that will be supplied to this component
     */
    form: {
      type: Object,
      default: () => {
        return {};
      }
    },
    itemId: {
      type: Number,
      default: null
    }
  },
  computed: {
    isRequired: function() {
      if (this.image) {
        return true;
      }

      return false;
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    defaultImage() {
      this.file = null;
      this.image = null;
    },
    createImage() {
      let reader = new FileReader();

      reader.onload = e => {
        this.image = e.target.result;
      };

      reader.readAsDataURL(this.file);
    }
  },
  mounted() {
    setTimeout(() => {
      this.image = this.form.image;
      this.file = this.form.file || null;
    }, 50);
  }
};
</script>
